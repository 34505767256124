@import '../../styling/variables.css';
.special-chars {
  white-space: normal !important;
}
.password-strength-meter {
  width: 100% !important;
}
.password-strength-meter-progress::-webkit-progress-bar {
  background-color: var(--color-gray);
  border-radius: 3px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-1::-webkit-progress-value {
  background-color: var(--danger) !important;
}
.strength-2::-webkit-progress-value {
  background-color: var(--warning) !important;
}
.strength-3::-webkit-progress-value {
  background-color: var(--success) !important;
}
.strength-3::-webkit-progress-value {
  background-color: var(--success) !important;
}
