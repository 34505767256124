@import '../../styling/variables.css';
.input {
  border: 1px solid var(--color-gray-light);
  border-radius: 3px;
  /* box-shadow: var(--box-shadow-2); */
  font-size: var(--font-size-md);
  padding: var(--spacing-sm) var(--spacing-md);
  transition: all 0.2s ease-in-out;
  margin-bottom: var(--spacing-md);
}

.input:disabled {
  background: var(--color-gray-lightest);
}

.input:focus {
  border-color: var(--color-primary);
  outline: none;
}

.input::placeholder {
  color: var(--color-gray-light);
}

.textarea {
  padding: var(--spacing-md);
}

.block {
  display: block;
  width: 100%;
}

.large {
  font-size: var(--font-size-lg);
  padding: var(--spacing-md) var(--spacing-lg);
}

.small {
  font-size: var(--font-size-sm);
  padding: var(--spacing-sm);
}
