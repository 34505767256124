.base {
  align-items: center;
  display: flex;
  border-radius: var(--border-radius-sm);
}

.sm {
  padding: var(--spacing-xxs) var(--spacing-md);
}

.md {
  padding: var(--spacing-sm) var(--spacing-lg);
}

.body {
  flex: 1;
  padding: var(--spacing-xs) 0;
}

.close {
  margin-left: var(--spacing-md);
  padding: var(--spacing-xs);
  line-height: var(--line-height-sm);
}

.default {
  & .body {
    color: white;
  }

  & .icon,
  & .close {
    color: white;
  }

  &.danger {
    background: var(--color-danger);
  }
  &.warning {
    background: var(--color-warning);
  }
  &.info {
    background: var(--color-primary);
    text-align: center;
    border-radius: 0%;
    span a {
        color: white;
        border-radius: var(--border-radius-pill);
        display: inline-block;
        border: 1px solid;
        font-size: var(--button-font-size);
        padding: var(--spacing-sm) var(--spacing-lg);
        line-height: 1;
        letter-spacing: var(--button-letter-spacing);
        margin-left:  var(--spacing-lg);
    }
  }
  &.success {
    background: var(--color-success);
  }
  &.muted {
    background: var(--color-gray);
  }
}

.inverted {
  background: var(--color-gray-darker);

  & .body {
    color: var(--color-gray-lighter);
  }

  & .close {
    color: var(--color-gray);

    &:hover {
      color: white;
    }
  }

  &.danger {
    & .icon {
      color: var(--color-danger-light);
    }
  }
  &.warning {
    & .icon {
      color: var(--color-warning-light);
    }
  }
  &.info {
    & .icon {
      color: var(--color-info-light);
    }
  }
  &.success {
    & .icon {
      color: var(--color-success-light);
    }
  }
  &.muted {
    & .icon {
      color: var(--color-gray);
    }
  }
}

.outlined {
  background: transparent;
  border: 1px solid;

  &.danger {
    border-color: var(--color-danger);

    & .body {
      color: var(--color-danger);
    }

    & .icon,
    & .close {
      color: var(--color-danger);
    }
  }
  &.warning {
    border-color: var(--color-warning);

    & .body {
      color: var(--color-warning);
    }

    & .icon,
    & .close {
      color: var(--color-warning);
    }
  }
  &.info {
    border-color: var(--color-info);

    & .body {
      color: var(--color-info);
    }

    & .icon,
    & .close {
      color: var(--color-info);
    }
  }
  &.success {
    border-color: var(--color-success);

    & .body {
      color: var(--color-success);
    }

    & .icon,
    & .close {
      color: var(--color-success);
    }
  }
  &.muted {
    border-color: var(--color-gray-lighter);

    & .body {
      color: var(--color-gray);
    }

    & .icon,
    & .close {
      color: var(--color-gray-light);
    }
  }
}
